@tailwind base;
@import "components.css";
@import "phone-input.css";
@import "full-calendar.css";
@tailwind utilities;

* {
  box-sizing: border-box;
}

body, html{
  height: 100vh;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

.full-calendar .fc .fc-h-event {
  background-color: unset;
  @apply border-0;
}

.fc .fc-daygrid-event-harness button:focus-visible {
  @apply border-0 outline-none;
}

.full-calendar .fc .fc-toolbar-chunk {
  @apply flex items-center;
}
.full-calendar .fc .fc-toolbar-title {
  @apply text-base text-gray-900 font-medium;
}
.full-calendar .fc .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  @apply ml-[10px];
}
.full-calendar .fc .fc-toolbar.fc-header-toolbar {
  @apply m-0 px-6 py-3;
}
.full-calendar .fc .fc-button {
  @apply p-0 px-2 h-6 text-xs bg-transparent border-none text-gray-700 font-medium;
}
.full-calendar .fc .fc-button.fc-prev-button,
.full-calendar .fc .fc-button.fc-next-button {
  @apply p-0 w-6 text-gray-600;
}
.full-calendar .fc .fc-button-primary:not(:disabled).fc-button-active,
.full-calendar .fc .fc-button-primary:not(:disabled):active {
  @apply bg-transparent border-none text-gray-700 shadow-none;
}
.full-calendar .fc .fc-button:focus {
  @apply shadow-none;
}
.full-calendar .fc .fc-toolbar-chunk:last-child .fc-button-group {
  @apply bg-gray-100 rounded p-px;
}
.full-calendar .fc .fc-button.fc-dayGridMonth-button,
.full-calendar .fc .fc-button.fc-timeGridWeek-button {
  @apply px-2 text-xs text-gray-600;
}
.full-calendar .fc .fc-button.fc-dayGridMonth-button.fc-button-active,
.full-calendar .fc .fc-button.fc-timeGridWeek-button.fc-button-active,
.full-calendar .fc .fc-button.fc-timeGridDay-button.fc-button-active {
  @apply rounded bg-white shadow-dialog text-gray-900;
}
.full-calendar .fc.fc-theme-standard .fc-scrollgrid {
  @apply border-t border-b-0 border-l-0 border-r-0 border-solid border-gray-100;
}
.full-calendar .fc.fc-theme-standard th {
  @apply border border-solid border-b-0 border-t-0 border-l-0 border-gray-100;
}
.full-calendar .fc.fc-theme-standard th:last-child {
  @apply border-none;
}
.full-calendar .fc .fc-col-header-cell-cushion {
  @apply text-xs font-medium text-gray-600 uppercase py-px;
}
.full-calendar .fc.fc-theme-standard td {
  @apply border border-solid border-l-0 border-t-0 border-gray-100;
}
.full-calendar .fc.fc-theme-standard td:last-child {
  @apply border-r-0;
}
.full-calendar .fc .fc-daygrid-day-top {
  @apply text-center w-full flex-1 justify-center text-xs text-gray-600;
}

.full-calendar .fc .fc-daygrid-day-top .fc-daygrid-day-number {
  @apply py-1 px-[5.5px] w-[52px] h-[26px] text-gray-900;
}
.fc .fc-day-other .fc-daygrid-day-top {
  @apply opacity-100;
}
.fc .fc-day-other .fc-daygrid-day-top .fc-daygrid-day-number {
  @apply text-gray-600;
}
.full-calendar .fc .fc-scrollgrid-section-body table tbody tr {
  @apply h-[60px];
}
.full-calendar .fc .fc-daygrid-day-events {
  @apply px-3 mt-1;
}
.full-calendar .fc .fc-daygrid-day.fc-day-today,
.full-calendar .fc .fc-timegrid-col.fc-day-today {
  @apply bg-transparent;
}
.full-calendar .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  @apply bg-primary-400 text-white rounded-full min-w-[26px];
}
.full-calendar .fc .fc-daygrid-day-frame {
  @apply pt-1 px-2 pb-2;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-scrollgrid-section.fc-scrollgrid-section-header .fc-scroller-harness  {
  @apply border border-b border-l-0 border-t-0 border-r-0 border-solid border-gray-100;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-col-header-cell-cushion {
  @apply p-2 w-full text-center flex items-center justify-center;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day.fc-day-past .time-grid__day-week {
  @apply text-xs font-medium text-gray-600;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day .time-grid__day-week {
  @apply text-xs font-medium text-gray-900;
}
.full-calendar .fc-timeGridDay-view .fc-col-header-cell.fc-day .time-grid__day-week {
  @apply text-xs font-medium text-gray-900 mb-0.5 text-center;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day.fc-day-past  .time-grid__day-number {
  @apply w-[44px] h-[44px] mt-0.5 flex items-center justify-center text-xl font-medium text-gray-600;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day .time-grid__day-number {
  @apply w-[44px] h-[44px] mt-0.5 flex items-center justify-center text-xl font-medium text-gray-900 whitespace-nowrap;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day.fc-day-today .time-grid__day-number {
  @apply bg-primary-400 text-white rounded-full;
}
.full-calendar .fc-timeGridDay-view .fc-col-header-cell.fc-day .time-grid__day-number {
  @apply w-[44px] h-[44px] inline-flex items-center justify-center bg-primary-400 text-white text-xl font-medium rounded-full;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-timegrid-slot {
  @apply h-[64px];
}
.full-calendar .fc .fc-timeGridWeek-view .fc-timegrid-slot:first-child {
  @apply align-top border-b-0;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-timegrid-slot-label,
.full-calendar .fc .fc-timeGridDay-view .fc-timegrid-slot-label {
  @apply text-xs text-gray-600;
}
.full-calendar .fc .fc-timeGridWeek-view .fc-timegrid-slot-label .fc-timegrid-slot-label-frame,
.full-calendar .fc .fc-timeGridDay-view .fc-timegrid-slot-label .fc-timegrid-slot-label-frame {
  @apply text-center -mt-2;
}

.full-calendar .fc .fc-scrollgrid-section-body table tbody tr:first-child .fc-timegrid-slot-label-frame {
  @apply mt-0;
}
.full-calendar .fc .fc-timegrid-event .fc-event-main {
  @apply p-0;
}
.full-calendar .fc .fc-daygrid-dot-event.fc-event-mirror,
.full-calendar .fc .fc-daygrid-dot-event:hover,
.full-calendar .fc .fc-daygrid-more-link:hover {
  @apply !bg-white;
}
.full-calendar .fc .fc-v-event {
  @apply bg-transparent border-none shadow-none outline-none;
}

.full-calendar .fc-timeGridDay-view .fc-scrollgrid-sync-inner,
.full-calendar .fc-timeGridDay-view th.fc-timegrid-axis {
  @apply border-b border-b-gray-100 p-2 text-left;
}

.full-calendar .fc-timeGridDay-view td.fc-scrollgrid-shrink {
  @apply border-b-0 align-top pt-2;
}
.full-calendar .fc-timeGridDay-view .fc-timegrid-slots tr:nth-child(1) td.fc-scrollgrid-shrink {
  @apply border-t border-t-gray-100 pt-0;
}
.full-calendar .fc .fc-more-popover {
  @apply rounded-lg border-0 overflow-hidden max-w-[206px] z-50;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.full-calendar .fc .fc-more-popover .fc-popover-body {
  @apply px-3 pb-3 pt-1 min-w-[206px];
}

.full-calendar .fc .fc-more-popover .fc-popover-header  {
  @apply bg-white pt-3 justify-center;
}

.full-calendar .fc .fc-more-popover .fc-popover-close {
  @apply absolute right-3;
}
.full-calendar .fc .fc-more-popover .fc-popover-close:before {
  font-size: 20px;
}

.full-calendar .fc .fc-timegrid-slot-label-cushion {
  @apply px-2;
}

.full-calendar .fc-direction-ltr .fc-timegrid-more-link {
  @apply flex bg-gray-50 mb-0 mt-[1px] h-5;
}

.full-calendar .fc-timegrid-more-link-inner {
  @apply px-1 py-0.5;
}

.full-calendar .fc .fc-popover-title {
  @apply text-gray-900 text-xl font-medium;
}

.full-calendar .fc-timeGridDay-view .fc-highlight,
.full-calendar .fc-timeGridWeek-view .fc-highlight {
  @apply bg-transparent;
}

.full-calendar .fc-timezoneText-button.fc-button {
  @apply text-sm text-gray-900 py-1.5 h-auto;
}

.button-- {
  color: #FFFFFF;
  background-color: #3869D4;
  border-top: 10px solid #3869D4;
  border-right: 18px solid #3869D4;
  border-bottom: 10px solid #3869D4;
  border-left: 18px solid #3869D4;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  /* margin-left: 40%; */
}
.button-pointer-events-none .button-- {
  pointer-events: none;
}

div[type=button] {
  -webkit-appearance: unset!important;
}

.bb-feedback-button-classic {
  @apply pointer-events-auto;
}

input.hide-number-arrows::-webkit-outer-spin-button,
input.hide-number-arrows::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input.hide-number-arrows[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}