@tailwind components;

@layer components {
  .table-tbody-hover .group-hover:not(.group-selected) {
    @apply hidden;
  }
  .table-tbody-selected,
  .table-tbody-hover:hover,
  .table-tbody-hover:hover td {
    @apply bg-gray-50;
  }
  .table-tbody-hover:hover .group-hover {
    @apply block;
  }

  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(calc(100% + 25px));
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes swipeOut {
    from {
      transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
      transform: translateX(calc(100% + 25px));
    }
  }

  .slideIn {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .hide {
    animation: hide 100ms ease-in;
  }
  .swipeOut {
    animation: swipeOut 100ms ease-out;
  }

  .funnel-chart text {
    @apply !text-sm
  }
}